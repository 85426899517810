import consoleLogger from '../../../../modules/console-logger'
import { useEffect } from 'react'
import LoadScript from '../LoadScript'

const SourcePointRestart = ({location}) => {
  const [consoleLog] = consoleLogger('Sourcepoint')
  const locationKey = location?.key

  useEffect(() => {
    return () => {
      const isDestroyMessageFunction = typeof window?._sp_?.destroyMessages === 'function'
      const isExecuteMessagingFunction = typeof window?._sp_?.executeMessaging === 'function'
      const isSpScript = window?._sp_
      if (!isSpScript || !isExecuteMessagingFunction || !isDestroyMessageFunction) {
        return
      }

      consoleLog('0 messages destroyed', '')
      window?._sp_?.destroyMessages()
      window?._sp_?.executeMessaging()
      consoleLog('1 execute messaging', '')
    }
  }, [locationKey])

  return true
}

export default SourcePointRestart
